import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';
import RSVP, { resolve } from 'rsvp';

import {
  dropTaskGroup,
  enqueueTask,
  restartableTask,
  task,
  TaskGenerator,
  TaskGroup,
  timeout,
} from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { Loaded } from 'mobile-web';
import { UserData } from 'mobile-web/lib/customer';
import { DELAY_MS } from 'mobile-web/lib/debounce';
import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import { SplitCheckStatus } from 'mobile-web/lib/split-check';
import { trackViewUpsellEvent } from 'mobile-web/lib/upsell';
import isSome from 'mobile-web/lib/utilities/is-some';
import BasketModel from 'mobile-web/models/basket';
import BasketProductModel from 'mobile-web/models/basket-product';
import FavoriteModel from 'mobile-web/models/favorite';
import OrderModel from 'mobile-web/models/order';
import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import ProductModel from 'mobile-web/models/product';
import UpsellGroupModel from 'mobile-web/models/upsell-group';
import UpsellGroup from 'mobile-web/models/upsell-group';
import UpsellItem from 'mobile-web/models/upsell-item';
import VendorSearchResultModel from 'mobile-web/models/vendor-search-result';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import ErrorService, { errorForUser } from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import GroupOrderService from 'mobile-web/services/group-order';
import NotificationsService, { NotificationType } from 'mobile-web/services/notifications';
import OnPremiseService from 'mobile-web/services/on-premise';
import StorageService from 'mobile-web/services/storage';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';
import VendorService from 'mobile-web/services/vendor';

import { ProductClickFrom, RecommendationSource } from './global-data';
import ReorderService from './reorder';
import ScrollService from './scroll';
import SessionService from './session';

export default class BasketService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service error!: ErrorService;
  @service features!: FeaturesService;
  @service globalEvents!: GlobalEventsService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service notifications!: NotificationsService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;
  @service vendor!: VendorService;
  @service reorder!: ReorderService;
  @service scroll!: ScrollService;
  @service session!: SessionService;

  // Untracked properties
  private untrackedActiveBasketProduct?: BasketProductModel;

  // Tracked properties
  @tracked basket?: BasketModel;
  @tracked private _isOpen = false;
  @tracked trackedActiveBasketProduct?: BasketProductModel;
  @tracked smartUpsellGroups?: UpsellGroupModel[] = [];
  @tracked hasOmnivoreDiscounts?: boolean;

  // Getters and setters
  get basketProducts(): BasketProductModel[] {
    return this.basket?.basketProducts.toArray() ?? [];
  }

  get isCallcenterOrder(): boolean {
    return this.basket?.isCallcenterOrder ?? false;
  }

  get basketCanBeSplit(): boolean {
    if (
      this.basket?.coupon ||
      this.basket?.coupon?.code ||
      this.basket?.hasReward ||
      this.hasOmnivoreDiscounts
    ) {
      return false;
    }
    return true;
  }

  get displayProducts(): BasketProductModel[] {
    return this.groupOrder.hasGroupOrder
      ? this.groupOrder.currentUserProducts
      : this.onPremise.openCheck
      ? this.openCheckUnsentBasketProducts
      : this.basketProducts;
  }

  get displayTotal(): number {
    return this.groupOrder.isParticipantMode
      ? this.groupOrder.currentUserProducts.reduce((total, p) => total + (p.totalCost ?? 0), 0)
      : this.basket?.total ?? 0;
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  get activeBasketProduct(): BasketProductModel | undefined {
    return this.trackedActiveBasketProduct;
  }

  set activeBasketProduct(bp: BasketProductModel | undefined) {
    // See https://github.com/emberjs/ember.js/issues/19192#issuecomment-807796249
    this.trackedActiveBasketProduct = this.untrackedActiveBasketProduct = bp;
  }

  get hasOnlySingleUseProducts(): boolean {
    return this.basketProducts.every((pro: BasketProductModel) => pro.product.get('isSingleUse'));
  }

  get receivingUser(): UserData | undefined {
    return this.basket?.receivingUser ?? this.storage.receivingCustomer;
  }

  get openCheckSentBasketProducts() {
    return this.basket?.onPremiseDetails?.experienceType === OnPremiseExperience.OpenCheck
      ? this.basketProducts.filter(x =>
          (this.basket?.onPremiseDetails?.openCheckSentBasketProducts ?? []).includes(Number(x.id))
        )
      : [];
  }

  get openCheckUnsentBasketProducts() {
    //the inverse of sentProduct
    return this.basket?.onPremiseDetails?.experienceType === OnPremiseExperience.OpenCheck
      ? this.basketProducts.filter(
          x =>
            !(this.basket?.onPremiseDetails?.openCheckSentBasketProducts ?? []).includes(
              Number(x.id)
            )
        )
      : [];
  }

  get enableSmartUpsellGroups(): boolean {
    return this.vendor?.vendor?.isSmartCrossSellEnabled || false;
  }
  get limitSmartUpsellItems(): 3 | 6 {
    const variation = this.features.flags['abtest-smart-upsell-limit-3-6-6-quick-add'] as string;
    return variation?.startsWith('smart-6') || this.vendor?.vendor?.isSmartCrossSellEnabled || false
      ? 6
      : 3;
  }
  get enableSmartUpsellMoreQuickAdd(): boolean {
    const variation = this.features.flags['abtest-smart-upsell-limit-3-6-6-quick-add'] as string;
    return (
      variation === 'smart-6-quick-add' || this.vendor?.vendor?.isSmartCrossSellEnabled || false
    );
  }
  get lastSmartCrossSellModelVersion(): string {
    return this.smartUpsellGroups?.reduce((prev, ug) => prev || ug.modelVersion, '') ?? '';
  }

  get isTaxExempt(): boolean {
    return this.basket?.isTaxExempt ?? false;
  }

  get taxExemptAccountId(): string | undefined {
    return this.basket?.taxExemptAccountId ?? undefined;
  }

  // Lifecycle methods

  // Other methods
  safeGetActiveBasketProduct(): BasketProductModel | undefined {
    return this.untrackedActiveBasketProduct;
  }

  clear(): void {
    this.basket?.unloadRecord();
    this.basket = undefined;
  }

  loadBasket(basketId: EmberDataId): RSVP.Promise<Loaded<BasketModel>> {
    return this.store.findRecord('basket', String(basketId).toUpperCase()).then(async basket => {
      this.basket = basket;

      if (this.basket.onPremiseDetails?.tablePosReference && !this.onPremise.tablePosRef) {
        // The basket has a table but somehow there is no tableposref cookie. This should be impossible, but it's a safety check.
        await this.onPremise.setSessionOnPremise(
          this.basket.onPremiseDetails?.tablePosReference,
          this.onPremise.experienceType
        );
      }
      return basket;
    });
  }

  refreshBasket(): RSVP.Promise<Loaded<BasketModel>> {
    if (this.basket) {
      return this.store
        .findRecord('basket', this.basket.id.toUpperCase(), { reload: true })
        .then(basket => {
          this.basket = basket;
          return this.basket;
        });
    }
    throw new Error('Cannot refresh a non-existent basket!');
  }

  async getOpenCheckItems() {
    if (this.basket) {
      const ticket = await this.basket.fetchOpenCheckItems();

      this.hasOmnivoreDiscounts =
        (ticket.discounts !== undefined && ticket.discounts.length > 0) ||
        (ticket.items !== undefined &&
          ticket.items.any(x => x.discounts !== undefined && x.discounts?.length > 0));

      return ticket;
    }
    throw new Error('Cannot get open check items from a non-existent basket!');
  }

  async sendOpenCheckProducts(): Promise<RSVP.Promise<BasketModel>> {
    if (this.basket) {
      return this.basket.sendOpenCheckProducts();
    }
    throw new Error('Cannot send open check items');
  }

  getSplitCheckStatuses(): RSVP.Promise<SplitCheckStatus[]> {
    if (this.basket) {
      return this.basket.fetchSplitCheckStatuses();
    }
    throw new Error('Cannot get split checks without a basket');
  }

  async setTaxExempt(isTaxExempt: boolean): Promise<void> {
    if (this.basket) {
      await this.basket.setTaxExempt({ isTaxExempt });
      return;
    }
    throw new Error('Cannot set tax exemption on a non-existent basket!');
  }

  getSmartUpsellDataIfVisible = task(async () => {
    if (this.basket && this.isOpen) {
      if (this.basket.isUpsellEnabled && this.enableSmartUpsellGroups) {
        const staticUpsells = this.basket.upsellGroups.toArray();
        let smartUpsellsReturned = false;
        try {
          const modelVariant = this.features.flags['abtest-smart-cross-sell-model-variant'];
          const smartUpsells = await this.basket.fetchSmartCrossSellGroups({
            itemLimit: this.limitSmartUpsellItems,
            modelVariant,
          });
          if (smartUpsells?.length) {
            this.smartUpsellGroups = smartUpsells;
            smartUpsellsReturned = true;
          } else {
            this.smartUpsellGroups = staticUpsells;
          }
        } catch (e) {
          this.smartUpsellGroups = staticUpsells;
        }
        trackViewUpsellEvent(
          this.smartUpsellGroups ?? [],
          this.store,
          this.analytics,
          smartUpsellsReturned
        );
      } else {
        this.smartUpsellGroups = [];
      }
    }
  });

  createBasket(vendor = this.vendor.vendor): RSVP.Promise<BasketModel> {
    if (this.basket && this.basket.vendor.get('id') === vendor?.id) {
      return resolve(this.basket);
    }

    const basket = this.store.createRecord('basket', { vendor });

    if (basket.referralToken === undefined) {
      basket.referralToken = this.storage.rwg_token;
    }
    return basket.save().then(
      () => {
        this.basket = basket;
        this.globalEvents.trigger(GlobalEventName.CreateBasket, basket.serializeForGlobalData());
        if (this.onPremise.tablePosRef || this.onPremise.isEnabled) {
          return basket.setOnPremiseDetails({
            tablePosReference: this.onPremise.tablePosRef,
            experienceType: this.onPremise.experienceType,
          });
        }
        return basket;
      },
      reason => {
        // If saving failed, we need to clean up the data from the local store
        basket.unloadRecord();
        throw reason;
      }
    );
  }

  onVendorUpdate(vendorId: EmberDataId): void {
    if (this.basket?.belongsTo('vendor').id() !== vendorId) {
      this.clear();
    }
  }

  reorderConfirm(basket: BasketModel, gotoCheckout: boolean) {
    let isConfirmed = false;
    const orderSummary = basket.basketProducts.map(bp => bp.productName).join(', ');
    const content = this.intl.t('mwc.recentOrders.reorder.confirmBasketText', { orderSummary });
    const opencart = !gotoCheckout;
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.recentOrders.reorder.confirmTitle'),
      content,
      buttonLabel: this.intl.t('mwc.recentOrders.reorder.confirmButton'),
      onConfirm: () => {
        isConfirmed = true;
        this.confirmReplaceOrderBasket(basket, opencart, gotoCheckout);
      },
      testSelector: 'confirmReorder',
      buttonTestSelector: 'confirmButton',
      onClose: () => {
        if (!isConfirmed) this.analytics.trackEvent(AnalyticsEvents.OrderItAgainModalCancelled);
      },
    });
  }

  confirmReplaceOrderBasket(basket: BasketModel, opencart: boolean, gotoCheckout: boolean) {
    this.replaceOrderBasket(basket, opencart, gotoCheckout);
    this.analytics.trackEvent(AnalyticsEvents.OrderItAgainModalConfirm);
    this.storage.orderItAgainClosed = true;
  }
  async activateAndReplace(reOrderBasket: BasketModel, gotoCheckout = false) {
    const hasProducts = !!this.basketProducts.length;
    const openCart = !gotoCheckout;
    await reOrderBasket.activate();

    if (hasProducts) {
      // reorder will pop up confirmation modal.
      this.reorderConfirm(reOrderBasket, gotoCheckout);
    } else {
      await this.replaceOrderBasket(reOrderBasket, openCart, gotoCheckout);
      this.storage.orderItAgainClosed = true;
    }
  }

  // Private methods

  // For baskets created from orders (recent orders, faves).
  // These baskets have no handoff or timewanted information.
  private async replaceOrderBasket(
    basket: BasketModel,
    openCart = true,
    gotoCheckout = false
  ): Promise<BasketModel> {
    const vendorId = basket.belongsTo('vendor').id();
    if (vendorId) {
      const vendor = await this.store.findRecord('vendor', vendorId, { reload: true });
      this.vendor.set('vendor', vendor);
    }
    this.basket = basket;

    if (openCart) {
      if (this.router.currentRouteName === 'menu.vendor.index') {
        this.open();
      } else {
        this.router.transitionTo('menu.vendor', this.vendor.vendor!.slug, {
          queryParams: { openBasket: true },
        });
      }
    }
    if (gotoCheckout) {
      this.router.transitionTo('checkout');
    }

    return basket;
  }

  /**
   * If the channel has special instructions and recipient disabled,
   * this method returns a matching product that is already in the basket.
   *
   * @param product product to search for
   * @returns `BasketProductModel` matching the given `ProductModel`
   */
  public findQuickAddModalProduct(product: ProductModel): BasketProductModel | undefined {
    const channelSettings = this.channel.settings;
    if (
      channelSettings?.showSpecialInstructions ||
      channelSettings?.showProductRecipientNameLabel
    ) {
      return undefined;
    }

    return this.findQuickUpdateProduct(product);
  }

  /**
   * This method returns an existing quick-add product in the
   * basket that matches the given vendor product id.
   *
   * @param product product to search for
   * @returns `BasketProductModel` matching the given `ProductModel`
   */
  public findQuickUpdateProduct(
    vendorProduct: ProductModel | undefined,
    recipientName = '',
    specialInstructions = ''
  ): BasketProductModel | undefined {
    if (!vendorProduct?.quickAddSupported) {
      return undefined;
    }

    const existingBlankBasketProduct = this.basket?.basketProducts
      ?.toArray()
      .find(
        p =>
          p.belongsTo('product').id() === vendorProduct.id &&
          (p.recipientName ?? '') === recipientName &&
          (p.specialInstructions ?? '') === specialInstructions
      );
    return existingBlankBasketProduct;
  }

  /**
   * Attempts to consolidate a given basketProduct into an existing
   * basketProduct in the cart.
   *
   * This only consolidates if the basketProduct supports quick add and if its
   * specialInstructions and recipientName are an exact match. If this is the
   * case, the incoming quantity is added to the exising quantity in the basket
   * and the existing product is returned.
   *
   * @param basketProduct product to attempt to consolidate
   * @returns consolidated basket product
   */
  public consolidate(basketProduct: BasketProductModel): BasketProductModel {
    if (!(basketProduct.product.content?.quickAddSupported ?? false)) {
      return basketProduct;
    }

    const existingBasketProduct = this.findQuickUpdateProduct(
      basketProduct.product.content,
      basketProduct.recipientName,
      basketProduct.specialInstructions
    );

    if (existingBasketProduct && basketProduct !== existingBasketProduct) {
      existingBasketProduct.quantity += basketProduct.quantity;
      // `basketProduct` will get unloaded by product-customization's `willDestroy`
    }

    return existingBasketProduct ?? basketProduct;
  }

  private quickAddNotify() {
    this.notifications.success({
      message: this.intl.t('mwc.notifications.quickAdded'),
      type: NotificationType.ProductQuickAdded,
    });
  }

  /**
   * Gets an existing basket product that is able to be quick-added to, or if
   * one doesn't exist, creates a new basket-product.
   * If we are in open check mode, create a new bp.
   */
  private async getQuickAddBasketProduct(product: ProductModel): Promise<BasketProductModel> {
    if (this.onPremise.hasOpenCheck) {
      const sentProducts = await Promise.all(this.openCheckSentBasketProducts.map(p => p.product));

      if (sentProducts.includes(product)) {
        return this.createNewBasketProductRecord(product);
      }
    }

    return Promise.resolve(
      this.basket?.basketProducts
        .toArray()
        .find(
          basketProduct =>
            basketProduct.canQuickAdd && basketProduct.belongsTo('product').id() === product.id
        ) ?? this.createNewBasketProductRecord(product)
    );
  }

  private createNewBasketProductRecord(product: ProductModel) {
    return this.store.createRecord('basket-product', {
      quantity: 0,
      product,
      vendor: product.get('vendor'),
    });
  }

  // Tasks

  updateTipTask = restartableTask(async (amount: number): Promise<void> => {
    await timeout(DELAY_MS);
    const basket = this.basket!;
    const newBasket = await basket.updateTip({ tip: amount });
    this.basket = newBasket;
  });

  @dropTaskGroup orderBasketGroup!: TaskGroup<void>;

  reorderTask = taskFor(this.reorderGenerator);
  @task({ group: 'orderBasketGroup' })
  *reorderGenerator(
    order: OrderModel | OrderSearchResultModel | FavoriteModel
  ): Generator<unknown, void, BasketModel> {
    const basket = yield order.reorder();
    if (isEmpty(basket.failures)) {
      yield this.replaceOrderBasket(basket);
    } else {
      const hasSuccesses = (basket.basketProducts.length as number) > 0;
      this.bus.trigger('confirm', {
        title: this.intl.t('mwc.reorder.failureModal.title'),
        content: this.intl.t('mwc.reorder.failureModal.body', {
          productList: basket.failures.map(f => f.productName).join(', '),
        }),
        buttonLabel: hasSuccesses
          ? this.intl.t('mwc.reorder.failureModal.addTheRestButton')
          : this.intl.t('mwc.reorder.failureModal.viewMenuButton'),
        buttonTestSelector: 'primaryFailureButton',
        cancelLabel: hasSuccesses
          ? this.intl.t('mwc.reorder.failureModal.viewMenuButton')
          : undefined,
        cancelTestSelector: 'secondaryFailureButton',
        onConfirm: async () => {
          await basket.activate();
          await this.replaceOrderBasket(basket);
        },
        onCancel: async () => {
          if (hasSuccesses) {
            this.router.transitionTo('menu.vendor', (await basket.vendor).slug);
          }
        },
      });
    }
  }

  editOrderTask = taskFor(this.editOrderGenerator);
  @task({ group: 'orderBasketGroup' })
  *editOrderGenerator(order: OrderModel): TaskGenerator<void> {
    const basket = yield order.edit();
    yield this.replaceOrderBasket(basket);
  }

  @task({ group: 'orderBasketGroup' })
  *transferTask(newVendor: VendorSearchResultModel): TaskGenerator<void> {
    // It is possible to get here without a basket if the order criteria modal gets an error.
    // If we don't have a basket, then just say we successfully "transferred"
    if (!this.basket || this.vendor.vendor?.get('slug') === newVendor.slug) {
      this.analytics.trackEvent(AnalyticsEvents.ChangeStoreLocation, () => ({
        [AnalyticsProperties.WarningShown]: false,
        [AnalyticsProperties.HasBasket]: true,
      }));

      yield this.basket;
    } else {
      try {
        const basket = yield this.basket!.transfer({ vendorId: newVendor.id });
        yield this.replaceOrderBasket(basket, false);

        const clean = isEmpty(this.basket.warnings);

        this.analytics.trackEvent(AnalyticsEvents.ChangeStoreLocation, () => ({
          [AnalyticsProperties.WarningShown]: !clean,
          [AnalyticsProperties.HasBasket]: true,
        }));

        if (clean) {
          const message =
            this.device.viewport !== 'Desktop'
              ? this.intl.t('mwc.basketTransferModal.successMessageShort')
              : this.intl.t('mwc.basketTransferModal.successMessage', { name: newVendor.name });

          this.notifications.success({
            message,
            type: NotificationType.CartTransferred,
          });
        } else {
          this.userFeedback.add({
            type: Type.Warning,
            title: this.intl.t('mwc.basketTransferModal.warningPartialTitle'),
            message: this.intl.t('mwc.basketTransferModal.warningPartialBody', {
              name: newVendor.name,
              products: this.basket.warnings.join(', '),
            }),
          });
        }
      } catch (e) {
        this.error.reportError(e);
      }
    }
  }

  deleteItems = task(async (products: BasketProductModel[]) => {
    for (const product of products) {
      await this.deleteItemTask.perform(product);
    }
  });

  private deleteItemTask = task({ enqueue: true }, async (product: BasketProductModel) => {
    const basketChoices = product.basketChoices.toArray();
    await product.destroyRecord().then(async () => {
      for (const choice of basketChoices) {
        await choice.unloadRecord();
      }
    });
  });

  public quickAddTask = task(
    async (product: ProductModel, clickFrom?: ProductClickFrom, onSuccess?: () => void) => {
      this.quickAddNotify();
      const basketProduct = await this.incrementQuickAddBasketProductTask.perform(
        product,
        clickFrom
      );
      onSuccess?.();
      return basketProduct;
    }
  );

  /**
   * Queues up quick-adds so that basket-products are consolidated whenever
   * possible.
   */
  private incrementQuickAddBasketProductTask = taskFor(this.incrementQuickAddBasketProductInstance);
  @enqueueTask
  private *incrementQuickAddBasketProductInstance(
    product: ProductModel,
    clickFrom?: ProductClickFrom
  ): TaskGenerator<BasketProductModel> {
    const basketProduct: BasketProductModel = yield this.getQuickAddBasketProduct(product);

    try {
      const previousQuantity = basketProduct.quantity;
      basketProduct.quantity += 1;

      yield basketProduct.saveTask.perform({
        eventName: 'Quick Add',
        clickFrom,
      });

      this.globalEvents.trigger(
        GlobalEventName.AddToCart,
        basketProduct.serializeForGlobalData(previousQuantity)
      );
    } catch (e) {
      const isNew = basketProduct.get('isNew');

      basketProduct.rollbackAttributes();

      if (errorForUser(e)) {
        this.error.reportError(e);
      } else {
        const message = this.intl.t(`mwc.notifications.failures.${isNew ? 'added' : 'updated'}`);
        this.notifications.error({
          message,
          type: NotificationType.ProductQuickAdded,
        });
        this.error.trackError(e, message);
      }
    }

    return basketProduct;
  }

  addUpsellItemTask = task(
    this,
    { drop: true },
    async (upsellItem: UpsellItem, upsellGroup: UpsellGroup) => {
      const basket = this.basket;
      // We can ignore the case where there's no basket because the only place
      // this task gets called is within the upsells that are shown when we
      // already have a basket.
      if (!basket) {
        return;
      }

      if (basket.groupOrderId) {
        upsellItem.recipientName = this.groupOrder.currentUserName;
      }
      await basket.addUpsellItem(upsellItem);

      let requiredCount = 0;
      let defaultCount = 0;
      let imageCount = 0;
      let priceSum = 0;
      const priceOfModifier: number[] = [];
      let totalModifiers = 0;

      const product = this.store.peekRecord('product', upsellItem.id);
      if (product) {
        this.globalEvents.trigger(
          GlobalEventName.ClickProductLink,
          {
            ...product.serializeForGlobalData(),
            hasImages: isSome(upsellItem.image),
            hasPrice: true,
          },
          ProductClickFrom.CartUpsell
        );
        product.get('optionGroups')?.forEach(og => {
          if (og.isRequired) requiredCount++;

          og.choices.forEach(ch => {
            if (og.isRequired && ch.get('isDefault')) defaultCount++;
            if (ch.image?.filename) imageCount++;
            const priceDifference: number = ch.get('priceDifference') ?? 0;
            priceSum += priceDifference;
            if (priceDifference > 0) {
              priceOfModifier.push(priceDifference);
            }

            totalModifiers++;
          });
        });
      }

      this.analytics.trackEvent(AnalyticsEvents.AddToCart, () => ({
        [AnalyticsProperties.ProductName]: upsellItem.name,
        [AnalyticsProperties.ProductCategory]: product?.category?.name,
        [AnalyticsProperties.ProductQuantity]: 1,
        [AnalyticsProperties.ProductBasePrice]: upsellItem.cost,
        [AnalyticsProperties.AddToCartMethod]: 'Upsell',
        [AnalyticsProperties.HasVisibleCalories]: isSome(upsellItem.calorieLabel),
        [AnalyticsProperties.VisibleLabels]: upsellItem.labels?.map(l => l.name),
        [AnalyticsProperties.HasProductImages]: isSome(upsellItem.image),
        [AnalyticsProperties.HasCategoryImages]: !isEmpty(product?.category?.images),
        [AnalyticsProperties.IsFeatured]: product?.isFeatured ?? false,
        [AnalyticsProperties.QuickAddSupported]: product?.quickAddSupported,
        [AnalyticsProperties.RecommendationSource]: RecommendationSource.CartCrossSell,
        [AnalyticsProperties.RecommendationModelVersion]: upsellGroup?.modelVersion,
        [AnalyticsProperties.RecommendationRecentProductCount]:
          upsellGroup?.recentProductCount ?? 0,
        [AnalyticsProperties.NumberOfTotalMods]: totalModifiers,
        [AnalyticsProperties.NumberOfRequiredMods]: requiredCount,
        [AnalyticsProperties.NumberOfDefaultedMods]: defaultCount,
        [AnalyticsProperties.NumberOfPricedMods]: priceOfModifier,
        [AnalyticsProperties.NumberOfImageMods]: imageCount,
        [AnalyticsProperties.SumOfModPrices]: priceSum,
      }));

      const message = this.intl.t('mwc.notifications.added', {
        quantity: upsellItem.quantity,
      });

      this.notifications.success({
        message,
        type: NotificationType.UpsellAdded,
      });

      await this.getSmartUpsellDataIfVisible.perform();
    }
  );

  // Actions and helpers
  @action
  open(): void {
    if (!this._isOpen) {
      this._isOpen = true;
      this.getSmartUpsellDataIfVisible.perform();
      this.analytics.trackEvent(AnalyticsEvents.ViewCartAction);
    }
  }

  @action
  close(): void {
    if (this._isOpen) {
      this._isOpen = false;
      this.analytics.trackEvent(AnalyticsEvents.CloseCartAction);
    }
  }

  @action
  toggle(): void {
    this._isOpen = !this._isOpen;
    if (this._isOpen) {
      this.getSmartUpsellDataIfVisible.perform();
      this.analytics.trackEvent(AnalyticsEvents.ViewCartAction);
    } else {
      this.analytics.trackEvent(AnalyticsEvents.CloseCartAction);
    }
  }

  openAndScrollIntoView(basketProductId: EmberDataId) {
    this.open();
    this.scroll.scrollIntoView(`[data-basket-product-id="${basketProductId}"]`, {
      alignToTop: true,
      waitCount: 2, // explicitly specifying defaults because we want to ensure the basket is opened before we try to scroll it
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    basket: BasketService;
  }
}
